<template>
  <validation-observer ref="simpleRules">
    <b-form>
      <b-row>
        <b-col md="12">
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Name"
              rules="required|alphaNum"
            >
              <b-form-input
                v-model.trim="name"
                :state="errors.length > 0 ? false:null"
                placeholder="Your Name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col md="12">
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Descriptions"
              rules="required"
            >
              <b-form-textarea
                id="textarea-rows"
                v-model="description"
                rows="8"
                :state="errors.length > 0 ? false:null"
                placeholder="Your description"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!-- submit button -->
        <b-col class="text-center">
          <b-button
            class="mr-1"
            variant="primary"
            type="submit"
            @click.prevent="validationForm"
          >
            Submit
          </b-button>

          <b-button
            variant="danger"
            type="button"
            @click="$emit('hidden')"
          >
            Cancel
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BFormTextarea,
} from 'bootstrap-vue'

import {
  required, confirmed,
} from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BFormTextarea,
    BRow,
    BCol,
    BButton,
  },
  data() {
    return {
      name: '',
      required,
      description: '',
      confirmed,
    }
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.$emit('insertRole' , {
            name: this.name,
            description: this.description,
          })
        }
      })
    },
  },
}
</script>
