var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-observer', {
    ref: "simpleRules"
  }, [_c('b-form', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', [_c('validation-provider', {
    attrs: {
      "name": "Name",
      "rules": "required|alphaNum"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "placeholder": "Your Name"
          },
          model: {
            value: _vm.name,
            callback: function callback($$v) {
              _vm.name = typeof $$v === 'string' ? $$v.trim() : $$v;
            },
            expression: "name"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', [_c('validation-provider', {
    attrs: {
      "name": "Descriptions",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('b-form-textarea', {
          attrs: {
            "id": "textarea-rows",
            "rows": "8",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Your description"
          },
          model: {
            value: _vm.description,
            callback: function callback($$v) {
              _vm.description = $$v;
            },
            expression: "description"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    staticClass: "text-center"
  }, [_c('b-button', {
    staticClass: "mr-1",
    attrs: {
      "variant": "primary",
      "type": "submit"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.validationForm($event);
      }
    }
  }, [_vm._v(" Submit ")]), _c('b-button', {
    attrs: {
      "variant": "danger",
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('hidden');
      }
    }
  }, [_vm._v(" Cancel ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }